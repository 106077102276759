// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-background-color-js": () => import("./../../../src/pages/background-color.js" /* webpackChunkName: "component---src-pages-background-color-js" */),
  "component---src-pages-blur-up-js": () => import("./../../../src/pages/blur-up.js" /* webpackChunkName: "component---src-pages-blur-up-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-prefer-webp-js": () => import("./../../../src/pages/prefer-webp.js" /* webpackChunkName: "component---src-pages-prefer-webp-js" */),
  "component---src-pages-traced-svg-js": () => import("./../../../src/pages/traced-svg.js" /* webpackChunkName: "component---src-pages-traced-svg-js" */)
}

